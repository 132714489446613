<template>
    <div>
        <v-alert v-if="$store.state.cows.error" type="error">{{$store.state.cows.error}}</v-alert>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>id</th>
                        <th>name</th>
                        <th>                          
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="cow in cows" :key="cow.id">
                        <td class="text-right">
                            {{cow.id}}
                        </td>
                        <td>
                            {{cow.name}}
                        </td>
                        <td class="text-right">
                            <v-btn icon @click="$refs.metricsCow.show(cow)">
                                <v-icon>mdi-chart-line</v-icon>
                            </v-btn>
                            <v-btn icon @click="$refs.editCow.show(cow)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <cow-edit ref="editCow" />
        <cow-metrics ref="metricsCow" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import CowEdit from '../dialogs/CowEdit.vue'
import CowMetrics from '../dialogs/CowMetrics.vue';

export default {
    components: {
        CowEdit,
        CowMetrics
    },

    computed: {
        ...mapState({
            cows: s => s.cows.cows
        })
    },

    methods: {
        ...mapActions({
            loadCows: 'cows/load'
        })
    },

    mounted() {
        this.loadCows();
    }
}
</script>