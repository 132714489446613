<template>
    <div>
        <marker-map :map="map" :markers="markers" @markerClick="markerClick" />
        <cow-metrics ref="metricsCow" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MarkerMap from '../components/MarkerMap'
import CowMetrics from '../dialogs/CowMetrics.vue'

export default {
    components: {
        MarkerMap,
        CowMetrics
    },

    methods: {
        markerClick(marker) {
            if(marker.cow) {
                this.$refs.metricsCow.show(marker.cow)
            }
        },

        ...mapActions({
            load: 'positions/load',
            stream: 'positions/streamPositions',
            stop: 'positions/stopPositions',
            loadCows: 'cows/load'
        })
    },

    data() {
        return {
            reloadCowsInterval: null,
        }
    },

    mounted() {
        this.load()
        this.stream()
        this.loadCows()

        this.reloadCowsInterval = setInterval(() => this.loadCows(), 5000)
    },

    beforeDestroy() {
        this.stop()

        if(this.reloadCowsInterval) {
            clearInterval(this.reloadCowsInterval)
        }
    },

    computed: {
        ...mapState({
            map: s => s.positions.map,
            positions: s => s.positions.positions,
            cows: s => s.cows.cows
        }),

        markers() {
            let res = []
            for(let [id, val] of Object.entries(this.positions)) {
                res.push({
                    label: id.toString(),
                    x: val.x,
                    y: val.y,
                    color: this.cows[id] ? 'lime' : 'red',
                    cow: this.cows[id] ? this.cows[id] : null,
                    clickable: this.cows[id] ? true: false
                })
            }

            return res
        }
    }
}
</script>