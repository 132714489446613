<template>
  <v-navigation-drawer
    :value="value"
    @input="$emit('input', $event)"
    app
    right
    temporary
  >
    <template v-if="!$store.getters['user/isValid']">
      <v-list nav dense flat>
        <v-list-item-group>
          <v-list-item @click="$router.push('/login')">
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                login
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-if="$store.getters['user/isValid']">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">{{$store.state.user.name}}</v-list-item-title>
          <v-list-item-subtitle v-if="$store.state.barn">{{$store.state.barn.name}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list nav dense flat>
        <v-list-item-group>
          <v-list-item v-if="$store.state.user.barns.length > 1" @click="$router.push('/barns')">
            <v-list-item-icon>
              <v-icon>mdi-barn</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                switch barn
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="!this.$store.state.user.rights.includes('demo') || showDemoLogout" @click="$store.dispatch('logout'); $router.push('/login')">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                logout
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: ["value"],

  data() {
    return {
      showDemoLogout: false,
      keydownFunc: null,
      keyupFunc: null
    }
  },

  mounted() {
    this.keydownFunc = (ev) => this.keydown(ev)
    this.keyupFunc = () => this.keyup()

    document.addEventListener('keydown', this.keydownFunc)
    document.addEventListener('keyup', this.keyupFunc)
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.keydownFunc)
    document.removeEventListener('keyup', this.keyupFunc)
  },

  methods: {
    keydown(ev) {
      this.showDemoLogout = ev.keyCode == 16
    },

    keyup() {
      this.showDemoLogout = false
    }
  }
};
</script>