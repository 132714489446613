<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title>
                edit {{camera.name}}
                <v-spacer />
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="camera.name" label="Name"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="save()">
                    save
                </v-btn>
                <v-spacer />
                <v-btn text @click="dialog = false">
                    cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            dialog: false,
            camera: {}
        }
    },

    methods: {
        show(camera) {
            this.dialog = true
            this.camera = JSON.parse(JSON.stringify(camera))
        },

        save() {
            this.editCamera(this.camera)

            this.dialog = false
        },

        ...mapActions({
            editCamera: 'cameras/edit'
        })
    }
}
</script>