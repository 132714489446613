import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    //dark: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
    themes: {
      light: {
        primary: '#FE0000',
        secondary: '#90a0b8',
        accent: '#CF3E3F',
        error: '#870000',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        primary: '#FE0000',
        secondary: '#90a0b8',
        accent: '#CF3E3F',
        error: '#870000',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
});
