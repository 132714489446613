import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import user from './user'
import users from './users'
import barns from './barns'
import cameras from './cameras'
import positions from './positions'
import cows from './cows'

Vue.use(Vuex)

const vuexSession = new VuexPersistence({
    storage: window.sessionStorage
})

const store = new Vuex.Store({
    state: {
        title: '',
        barn: null
    },

    mutations: {
        setTitle: (state, title) => state.title = title,
        setBarn: (state, barn) => state.barn = barn
    },

    actions: {
        logout({ commit }) {
            commit('setBarn', null)
        }
    },

    modules: {
        user,
        users,
        barns,
        cameras,
        positions,
        cows
    },

    plugins: [vuexSession.plugin]
})

export default store