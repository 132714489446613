<template>
    <div>
        <v-alert v-if="$store.state.users.error" type="error">{{$store.state.users.error}}</v-alert>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>name</th>
                        <th class="text-right">
                            <v-btn icon @click="$refs.newUser.show()">
                                <v-icon>mdi-account-plus</v-icon>
                            </v-btn>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" :key="user.id">
                        <td>
                            {{user.name}}
                        </td>
                        <td class="text-right">
                            <v-btn icon @click="$refs.editUser.show(user)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon @click="$refs.userPassword.show(user)">
                                <v-icon>mdi-form-textbox-password</v-icon>
                            </v-btn>
                            <v-btn icon @click="$refs.userRights.show(user)">
                                <v-icon>mdi-key</v-icon>
                            </v-btn>
                            <v-btn icon @click="$refs.userBarns.show(user)">
                                <v-icon>mdi-barn</v-icon>
                            </v-btn>
                            <v-btn icon @click="remove(user)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <user-new ref="newUser" />
        <yes-no ref="removeUser">
            sure?
        </yes-no>
        <user-edit ref="editUser" />
        <user-rights ref="userRights" />
        <user-barns ref="userBarns" />
        <user-password ref="userPassword" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import YesNo from '../dialogs/YesNo'
import UserNew from '../dialogs/UserNew'
import UserEdit from '../dialogs/UserEdit'
import UserBarns from '../dialogs/UserBarns.vue'
import UserRights from '../dialogs/UserRights.vue'
import UserPassword from '../dialogs/UserPassword.vue'

export default {
    components: {
        YesNo,
        UserNew,
        UserEdit,
        UserBarns,
        UserRights,
        UserPassword
    },

    computed: {
        ...mapState({
            users: s => s.users.users
        })
    },

    methods: {
        async remove(user) {
            if (await this.$refs.removeUser.show()) {
                this.removeUser(user)
            }
        },

        ...mapActions({
            loadUsers: 'users/load',
            removeUser: 'users/remove'
        })
    },

    mounted() {
        this.loadUsers();
    }
}
</script>