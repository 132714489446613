import fetcher from '../fetcher'
import {api_url} from '../config.json'

import store from '.'

setTimeout(() => {
    if (store.getters['user/isValid']) {
        store.dispatch('user/refreshLogin')
    }
}, 1000)

export default {
    namespaced: true,
    state: {
        error: false,
        token: '',
        expireDate: new Date(1970, 1, 1),
        name: '',
        barns: [],
        rights: []
    },

    mutations: {
        setError: (state, error) => state.error = error,
        setToken: (state, token) => state.token = token,
        setExpireDate: (state, date) => state.expireDate = date,
        setName: (state, name) => state.name = name,
        setBarns: (state, barns) => state.barns = barns,
        setRights: (state, rights) => state.rights = rights,
    },

    actions: {
        login: {
            root: true,
            async handler(context, credentials) {
                const { commit, dispatch } = context

                let res = await fetcher(api_url + '/user/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(credentials)
                })

                if (res.status != 200) {
                    commit('setError', await res.text())
                    return
                }

                let user = await res.json()

                dispatch('assignUser', user)
            }
        },

        async assignUser({ commit, dispatch }, user) {
            const expireDate = new Date(user.expireDate)

            commit('setToken', user.token)
            commit('setExpireDate', expireDate)
            commit('setName', user.name)
            commit('setRights', user.rights)

            let res = await fetcher(api_url + '/data/barns')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            let barns = await res.json()

            commit('setBarns', barns)
            commit('setError', false)

            setTimeout(() => dispatch('refreshLogin'), expireDate.valueOf() - (new Date()).valueOf() - 30*1000)
        },

        logout: {
            root: true,
            handler({ commit }) {
                commit('setToken', '')
                commit('setExpireDate', new Date(1970, 1, 1))
                commit('setName', '')
                commit('setBarns', [])
            }
        },

        async refreshLogin({ commit, dispatch }) {
            let res = await fetcher(api_url + '/user/refresh_login')
            if (res.status != 200) {
                commit('setError', await res.text())
                dispatch('logout')
                return
            }

            let user = await res.json()
            
            dispatch('assignUser', user)
        }
    },

    getters: {
        isValid: s => s.token.length > 0 && new Date(s.expireDate) > new Date(),
        hasRight: s => r => s.rights.includes('*') || s.rights.includes(r),
        hasRights: s => r => s.rights.includes('*') || r.reduce((v, r) => v && s.includes(r), true)
    }
}