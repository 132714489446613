import fetcher from '../fetcher'
import {api_url} from '../config.json'

export default {
    namespaced: true,
    state: {
        error: false,
        map: null,
        positions: {},
        _interval: null
    },

    mutations: {
        setError: (state, error) => state.error = error,
        setMap: (state, map) => state.map = map,
        setPositions: (state, positions) => state.positions = positions,
        _setInterval: (state, _interval) => state._interval = _interval
    },

    actions: {
        async load({ commit, dispatch, rootState }) {
            var res = await fetcher(api_url + '/data/barn/' + rootState.barn?.id?.toString() + '/map')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            let blob = await res.blob()
            let image = URL.createObjectURL(blob)

            commit('setMap', image)

            dispatch('loadPositions')

            commit('setError', false)
        },

        async loadPositions({ commit, rootState }) {
            var res = await fetcher(api_url + '/data/barn/' + rootState.barn?.id?.toString() + '/latest_positions')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            var positions = await res.json()

            commit('setPositions', positions)
            commit('setError', false)
        },

        async streamPositions({ commit, dispatch }) {
            dispatch('stopPositions')
            let interval = setInterval(() => dispatch('loadPositions'), 1000)
            commit('_setInterval', interval)
        },

        async stopPositions({ state, commit }) {
            if(state._interval) {
                clearInterval(state._interval)
                commit('_setInterval', null)
            }
        }
    }
}