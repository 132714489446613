<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title>
                edit {{cow.name}}
                <v-spacer />
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="cow.name" label="Name"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="save()">
                    save
                </v-btn>
                <v-spacer />
                <v-btn text @click="dialog = false">
                    cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            dialog: false,
            cow: {}
        }
    },

    methods: {
        show(cow) {
            this.dialog = true
            this.cow = JSON.parse(JSON.stringify(cow))
        },

        save() {
            this.editCow(this.cow)

            this.dialog = false
        },

        ...mapActions({
            editCow: 'cows/edit'
        })
    }
}
</script>