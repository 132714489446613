import fetcher from '../fetcher'
import {api_url} from '../config.json'

export default {
    namespaced: true,
    state: {
        error: false,
        cows: {},
        activities: {},
        tracks: {},
        heatmaps: {}
    },

    mutations: {
        setError: (state, error) => state.error = error,
        setCows: (state, cows) => state.cows = cows,
        setActivity: (state, cowActivity) => {
            let obj = {}
            obj[cowActivity.cow.id] = cowActivity.activity
            state.activities = {...state.activities, ...obj}
        },
        setTrack: (state, cowTrack) => {
            let obj = {}
            obj[cowTrack.cow.id] = cowTrack.track
            state.tracks = {...state.tracks, ...obj}
        },
        setHeatmap: (state, cowHeatmap) => {
            let obj = {}
            obj[cowHeatmap.cow.id] = cowHeatmap.heatmap
            state.heatmaps = {...state.heatmaps, ...obj}
        }
    },

    actions: {
        async load({ commit, rootState }) {
            var res = await fetcher(api_url + '/data/barn/' + rootState.barn?.id?.toString() + '/cows')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            let cowsArr = await res.json()

            let cows = {}

            cowsArr.forEach(c => cows[c.id] = c)

            commit('setCows', cows)
            commit('setError', false)
        },

        async edit({ commit, dispatch, rootState }, cow) {
            let res = await fetcher(api_url + '/data/barn/' + rootState.barn?.id?.toString() + '/cow/' + cow.id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cow)
            })

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            dispatch('load')
        },

        async loadActivity({ commit, rootState }, cow) {
            let res = await fetcher(api_url + '/data/barn/' + rootState.barn?.id?.toString() + '/cow/' + cow.id + '/activity')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            let activity = await res.json()
            commit('setActivity', { cow, activity })
        },

        async loadTrack({ commit, rootState }, cow) {
            let res = await fetcher(api_url + '/data/barn/' + rootState.barn?.id?.toString() + '/cow/' + cow.id + '/tracks')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            let track = await res.json()
            commit('setTrack', { cow, track })
        },

        async loadHeatmap({ commit, rootState }, cow) {
            let res = await fetcher(api_url + '/data/barn/' + rootState.barn?.id?.toString() + '/cow/' + cow.id + '/heatmap')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            let heatmap = await res.json()
            commit('setHeatmap', { cow, heatmap })
        }
    }
}