<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title>
                Change password for {{user.name}}
                <v-spacer />
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="password" label="Password" :type="showPw ? 'text' : 'password'" :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPw = !showPw" required></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="change()">
                    change
                </v-btn>
                <v-spacer />
                <v-btn text @click="dialog = false">
                    cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            dialog: false,
            user: {},
            password: '',
            showPw: false
        }
    },

    methods: {
        show(user) {
            this.dialog = true
            this.user = user
            this.password = ''
        },

        change() {
            this.setPassword({
                user: this.user,
                password: this.password
            })

            this.dialog = false
        },

        ...mapActions({
            setPassword: 'users/setPassword'
        })
    }
}
</script>