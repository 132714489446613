<template>
    <div>
        <v-alert v-if="$store.state.barns.error" type="error">{{$store.state.barns.error}}</v-alert>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>name</th>
                        <th class="text-right">
                            <v-btn icon @click="$refs.newBarn.show()">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="barn in barns" :key="barn.id">
                        <td>
                            {{barn.name}}
                        </td>
                        <td class="text-right">
                            <v-btn icon @click="$refs.editBarn.show(barn)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon @click="remove(barn)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <barn-new ref="newBarn" />
        <yes-no ref="removeBarn">
            sure?
        </yes-no>
        <barn-edit ref="editBarn" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import YesNo from '../dialogs/YesNo'
import BarnNew from '../dialogs/BarnNew'
import BarnEdit from '../dialogs/BarnEdit'

export default {
    components: {
        YesNo,
        BarnNew,
        BarnEdit
    },

    computed: {
        ...mapState({
            barns: s => s.barns.barns
        })
    },

    methods: {
        async remove(barn) {
            if (await this.$refs.removeBarn.show()) {
                this.removeBarn(barn)
            }
        },

        ...mapActions({
            loadBarns: 'barns/load',
            removeBarn: 'barns/remove'
        })
    },

    mounted() {
        this.loadBarns();
    }
}
</script>