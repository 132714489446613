<template>
  <div>
    <v-list v-if="barns.length > 0">
      <v-list-item-group>
        <v-list-item @click="selectBarn(barn)" v-for="barn in barns" :key="barn.id">
          <v-list-item-title>{{barn.name}}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template v-if="barns.length == 0">
      there are no barns assigned to you
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      barns: (s) => s.user.barns,
    }),
  },

  methods: {
    selectBarn(barn) {
      this.$store.commit("setBarn", barn);
      this.$router.replace("/")
    },
  },

  mounted() {
    if (this.barns.length == 1) {
      this.selectBarn(this.barns[0])
    }
  },
};
</script>