import fetcher from '../fetcher'
import {api_url} from '../config.json'

export default {
    namespaced: true,
    state: {
        error: false,
        barns: []
    },

    mutations: {
        setError: (state, error) => state.error = error,
        setBarns: (state, barns) => state.barns = barns
    },

    actions: {
        async load({ commit }) {
            var res = await fetcher(api_url + '/data/barns')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            let barns = await res.json()

            commit('setBarns', barns)
            commit('setError', false)
        },

        async edit({ commit, dispatch }, barn) {
            let res = await fetcher(api_url + '/data/barn/' + barn.id.toString(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(barn)
            })

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            dispatch('load')
        },

        async add({ commit, dispatch }, barn)
        {
            let res = await fetcher(api_url + '/data/barn/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(barn)
            })

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            dispatch('load')
        },

        async remove({ commit, dispatch }, barn) {
            var res = await fetcher(api_url + '/data/barn/' + barn.id.toString() + '/remove')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            commit('setError', false)
            dispatch('load')
        }
    }
}