import fetcher from '../fetcher'
import {api_url} from '../config.json'

export default {
    namespaced: true,
    state: {
        error: false,
        users: [],
        rights: {},
        barns: {},
        availableRights: [],
    },

    mutations: {
        setError: (state, error) => state.error = error,
        setUsers: (state, users) => state.users = users,
        setRights: (state, userRights) => state.rights[userRights.user.id] = userRights.rights,
        setBarns: (state, userBarns) => state.barns[userBarns.user.id] = userBarns.barns,
        setAvailableRights: (state, availableRights) => state.availableRights = availableRights
    },

    actions: {
        async load({ commit }) {
            var res = await fetcher(api_url + '/user/list')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            let users = await res.json()

            commit('setUsers', users)
            commit('setError', false)
        },

        async loadRights({ commit }, user) {
            var res = await fetcher(api_url + '/user/rights/' + user.id.toString())

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            let rights = await res.json()

            commit('setRights', {user, rights})
            commit('setError', false)
        },

        async loadAvailableRights({ commit }) {
            var res = await fetcher(api_url + '/user/available_rights')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            let rights = await res.json()

            commit('setAvailableRights', rights)
            commit('setError', false)
        },

        async loadBarns({ commit }, user) {
            var res = await fetcher(api_url + '/user/barns/' + user.id.toString())

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            let barns = await res.json()

            commit('setBarns', {user, barns})
            commit('setError', false)
        },

        async edit({ commit, dispatch }, user) {
            let res = await fetcher(api_url + '/user/user/' + user.id.toString(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            })

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            dispatch('load')
        },

        async add({ commit, dispatch }, credentials)
        {
            let res = await fetcher(api_url + '/user/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(credentials)
            })

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            dispatch('load')
        },

        async setPassword({ commit }, userPassword)
        {
            const { user, password } = userPassword

            let res = await fetcher(api_url + '/user/password/' + user.id.toString(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ password })
            })

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }
        },

        async addRight({ commit, dispatch }, userRight) {
            const { user, right } = userRight

            let res = await fetcher(api_url + '/user/right/' + user.id.toString() + '/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ right })
            })

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            dispatch('loadRights', user)
        },

        async addBarn({ commit, dispatch }, userBarn) {
            const { user, barn } = userBarn

            let res = await fetcher(api_url + '/user/barn/' + user.id.toString() + '/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ barn: barn.id })
            })

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            dispatch('loadBarns', user)
        },

        async remove({ commit, dispatch }, user) {
            var res = await fetcher(api_url + '/user/remove/' + user.id.toString())

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            commit('setError', false)
            dispatch('load')
        },

        async removeRight({ commit, dispatch }, userRight) {
            const { user, right } = userRight

            let res = await fetcher(api_url + '/user/right/' + user.id.toString() + '/remove', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ right })
            })

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            dispatch('loadRights', user)
        },

        async removeBarn({ commit, dispatch }, userBarn) {
            const { user, barn } = userBarn

            let res = await fetcher(api_url + '/user/barn/' + user.id.toString() + '/remove', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ barn: barn.id })
            })

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            dispatch('loadBarns', user)
        }
    }
}