<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title>
                Rights of {{user.name}}
                <v-spacer />
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-combobox 
                    ref="newRight" 
                    label="add new right" 
                    v-model="newRight" 
                    :items="availableRights" 
                    dense
                    append-icon="mdi-plus"
                    @click:append="add()"
                />
                <v-simple-table>
                    <template v-slot:default>
                        <tbody>                      
                            <tr v-for="right in rights" :key="right">
                                <td>
                                    {{right}}
                                </td>
                                <td class="text-right">
                                    <v-btn icon @click="remove(right)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="dialog = false">
                    close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    data() {
        return {
            dialog: false,
            user: {},
            newRight: ''
        }
    },

    computed: {
        ...mapState({
            userRights: s => s.users.rights,
            availableRights: s => s.users.availableRights
        }),

        rights() {
            if(!Array.isArray(this.userRights[this.user.id])) return []

            return this.userRights[this.user.id]
        }
    },

    methods: {
        show(user) {
            this.dialog = true
            this.user = user

            this.newRight = ''
            this.loadRights(user)
            this.loadAvailableRights()
        },

        add() {
            this.$refs.newRight.blur()

            this.$nextTick(() => {
                this.addRight({
                    user: this.user,
                    right: this.newRight
                })

                this.newRight = ''
            })
        },

        remove(right) {
            this.removeRight({
                user: this.user,
                right
            })
        },

        ...mapActions({
            loadRights: 'users/loadRights',
            loadAvailableRights: 'users/loadAvailableRights',
            addRight: 'users/addRight',
            removeRight: 'users/removeRight'
        })
    }
}
</script>