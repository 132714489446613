<template>
    <div>
        <v-alert v-if="$store.state.cameras.error" type="error">{{$store.state.cameras.error}}</v-alert>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(camera, id) in cameras" :key="id">
                        <td>
                            {{camera.name}}
                        </td>
                        <td class="text-right">
                            <v-btn icon @click="viewId=id; $refs.viewCamera.show()">
                                <v-icon>mdi-cctv</v-icon>
                            </v-btn>
                            <v-btn v-if="$store.getters['user/hasRight']('camera_update')"  icon @click="$refs.editCamera.show(camera)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <camera-view ref="viewCamera" :camera="cameras[viewId]" />
        <camera-edit ref="editCamera" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CameraEdit from '../dialogs/CameraEdit'
import CameraView from '../dialogs/CameraView'

export default {
    components: {
        CameraEdit,
        CameraView
    },

    data() {
        return {
            viewId: null
        }
    },

    computed: {
        ...mapState({
            cameras: s => s.cameras.cameras
        })
    },

    methods: {
        ...mapActions({
            load: 'cameras/load',
            loadLatest: 'cameras/loadLatest'
        })
    },

    mounted() {
        this.load()
    }
}
</script>