<template>
    <div>
        <activity-plot :cows=cows />    
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ActivityPlot from '../components/ActivityPlot.vue'
export default {
    components: { ActivityPlot },
    
    computed: {
        ...mapState({
            cows: s => Object.values(s.cows.cows)
        })
    },

    methods: {
        ...mapActions({
            loadCows: 'cows/load'
        })
    },

    mounted() {
        this.loadCows()
    }
}
</script>