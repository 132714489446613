<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title :key="rerender">
                <template v-if="camera && camera.playback">
                    {{camera.name}} - {{camera.playback.stamp.toLocaleDateString()}} {{camera.playback.stamp.toLocaleTimeString()}}
                </template>
                <v-spacer />
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <camera-video v-if="dialog" :camera="camera" class="video" />
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="dialog = false">
                    close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import CameraVideo from '../components/CameraVideo.vue'

export default {
    components: { CameraVideo },
    props: ['camera'],

    data() {
        return {
            dialog: false
        }
    },

    computed: {
        ...mapState({
            rerender: s => s.cameras.rerender
        })
    },

    methods: {
        show() {
            this.dialog = true
        }
    }
}
</script>

<style lang="scss" scoped>
.video {
    width: 100%;
    height: 100%;
}
</style>