<template>
  <v-form @submit.prevent="login">
    <v-alert v-if="$store.state.user.error" type="error">{{$store.state.user.error}}</v-alert>
    <v-text-field v-model="user" label="Username" required></v-text-field>
    <v-text-field v-model="password" label="Password" :type="showPw ? 'text' : 'password'" :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPw = !showPw" required></v-text-field>
    <v-btn type="submit">
        Login
    </v-btn>
  </v-form>
</template>

<script>
import NoSleep from 'nosleep.js'

export default {
  data() {
    return {
      user: "",
      password: "",
      showPw: false,
      noSleep: new NoSleep()
    };
  },

  mounted() {
    this.user = ""
    this.password = ""

    if (this.$store.getters['user/isValid']) {
        this.$router.replace('/')
    }
  },

  methods: {
      async login() {
          this.noSleep.disable()

          await this.$store.dispatch('login', {
              name: this.user,
              password: this.password
          })
          this.$router.replace('/')

          if(this.$store.state.user.rights.includes('demo')) {
            this.noSleep.enable();
            console.warn('noSleep active')
          }
      }
  }
};
</script>