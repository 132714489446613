<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title>
                New Barn
                <v-spacer />
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="newName" label="Name" required></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="newBarn()">
                    create
                </v-btn>
                <v-spacer />
                <v-btn text @click="dialog = false">
                    cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {

    data() {
        return {
            dialog: false,
            newName: ''
        }
    },

    methods: {
        show() {
            this.newName = ''
            this.dialog = true
        },

        newBarn() {
            var barn = {
                name: this.newName
            }

            this.addBarn(barn)

            this.dialog = false
        },

        ...mapActions({
            addBarn: 'barns/add'
        })
    }
}
</script>