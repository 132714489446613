<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title>
                Barns assigned to {{user.name}}
                <v-spacer />
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-select 
                    ref="newBarn" 
                    label="assign barn" 
                    v-model="newBarn" 
                    :items="availableBarns" 
                    item-text="name"
                    :return-object="true"
                    dense
                    append-icon="mdi-plus"
                    @click:append="add()"
                />
                <v-simple-table>
                    <template v-slot:default>
                        <tbody>                      
                            <tr v-for="barn in barns" :key="barn.id">
                                <td>
                                    {{barn.name}}
                                </td>
                                <td class="text-right">
                                    <v-btn icon @click="remove(barn)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="dialog = false">
                    close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    data() {
        return {
            dialog: false,
            user: {},
            newBarn: null
        }
    },

    computed: {
        ...mapState({
            userBarns: s => s.users.barns,
            availableBarns: s => s.user.barns
        }),

        barns() {
            if(!Array.isArray(this.userBarns[this.user.id])) return []

            return this.userBarns[this.user.id]
        }
    },

    methods: {
        show(user) {
            this.dialog = true
            this.user = user

            this.newBarn = null
            this.loadBarns(this.user)
        },

        add() {
            this.$refs.newBarn.blur()

            this.$nextTick(() => {
                this.addBarn({
                    user: this.user,
                    barn: this.newBarn
                })
            })
        },

        remove(barn) {
            this.removeBarn({
                user: this.user,
                barn
            })
        },        

        ...mapActions({
            loadBarns: 'users/loadBarns',
            addBarn: 'users/addBarn',
            removeBarn: 'users/removeBarn'
        })
    }
}
</script>