<template>
    <div ref="container">
        <canvas ref="canvas" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    props: ['cows'],

    data() {
        return {
            isLoading: true
        }
    },

    computed: {
        ...mapState({
            map: s => s.positions.map,
            heatmaps: s => s.cows.heatmaps
        })
    },

    watch: {
        cows() {
            this.load()
        },

        map() {
            this.draw()
        },

        heatmaps() {
            this.draw()
        },

        isLoading() {
            this.draw()
        }
    },

    mounted() {
        this.loadPositions()
        this.load()
    },

    methods: {
        load() {
            this.isLoading = true
            let promises = this.cows.map(cow => this.loadHeatmap(cow))
            Promise.all(promises).then(() => this.isLoading = false).catch(() => this.isLoading = false)
        },

        async draw() {
            if(this.isLoading) return

            let container = this.$refs.container
            let canvas = this.$refs.canvas
            let ctx = canvas.getContext("2d")
            let img = new Image()

            img.onload = () => {
                canvas.width = container.clientWidth
                canvas.height = (canvas.width / img.width) * img.height

                ctx.fillStyle = "white"
                ctx.fillRect(0,0, canvas.width, canvas.height)

                let scale = Math.min(canvas.width / img.width, canvas.height / img.height);
                let x = (canvas.width / 2) - (img.width / 2) * scale;
                let y = (canvas.height / 2) - (img.height / 2) * scale;

                ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

                let imageData

                try {
                    imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                } catch {
                    return
                }

                let data = this.analyze(imageData.width, imageData.height)

                const pixels = imageData.data;
                for (let x = 0; x < imageData.width; x++) {
                    for (let y = 0; y < imageData.height; y++) {
                        let base = y * (imageData.width * 4) + x * 4

                        let r = pixels[base + 0]
                        let g = pixels[base + 1]
                        let b = pixels[base + 2]

                        let v = Math.trunc(data[x][y] * 200)
                        
                        r = Math.max(r - v, 0)
                        g = Math.min(g + v, 255)
                        b = Math.max(b - v, 0)

                        pixels[base]     = r
                        pixels[base + 1] = g
                        pixels[base + 2] = b
                    }
                }
                ctx.putImageData(imageData, 0, 0);
            }

            img.src = this.map
        },

        analyze(width, height) {
            let data = new Array(width).fill(0.0).map(() => new Array(height).fill(0.0))

            const r = Math.trunc(Math.sqrt(width**2 + height**2) / 20);

            const drawPoint = (x, y) => {
                for(let bx = -r; bx <= r; bx++)
                {
                    let ix = x + bx

                    if(ix < 0 || ix >= width) {
                        continue
                    }

                    let bxs = bx/r

                    for(let by = -r; by <= r; by++) {
                        let iy = y + by

                        if(iy < 0 || iy >= height) {
                            continue
                        }

                        let v = 1 - Math.sqrt(bxs**2 + (by/r)**2)
                        v = Math.max(v, 0)
                        v = Math.min(v, 1)

                        data[ix][iy] += v
                    }
                }
            }

            // let take = this.cows.reduce((t, c) => t + this.tracks[c.id].length, 0) / 2000
            // let counter = 0

            this.cows.forEach(c => {
                if (this.heatmaps[c.id]) {
                    this.heatmaps[c.id].forEach(t => {
                        // counter++;
                        if(/*counter > take &&*/ Array.isArray(t.position)) {
                            let x = Math.trunc(t.position[0] * width)
                            let y = Math.trunc(t.position[1] * height)

                            drawPoint(x, y)
                            // counter = 0
                        }
                    })
                }
            })

            for(let x = 0; x < width; x++) {
                for(let y = 0; y < width; y++) {
                    data[x][y] = Math.min(data[x][y], 1)
                    data[x][y] = Math.max(data[x][y], 0)
                }                
            }

            return data
        },

        ...mapActions({
            loadHeatmap: 'cows/loadHeatmap',
            loadPositions: 'positions/load'
        })
    }
}
</script>