<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title>
                <template v-if="cow">
                    {{cow.id}} - {{cow.name}}
                </template>
                <v-spacer />
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <h3>Activity</h3>
                <activity-plot :cows="[cow]" />
                <h3>Heatmap</h3>
                <heatmap-plot :cows="[cow]" />
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="dialog = false">
                    close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ActivityPlot from '../components/ActivityPlot'
import HeatmapPlot from '../components/HeatmapPlot'

export default {
    components: { 
        ActivityPlot,
        HeatmapPlot
    },

    data() {
        return {
            dialog: false,
            cow: []
        }
    },

    methods: {
        show(cow) {
            this.dialog = true
            this.cow = cow
        }
    }
}
</script>

<style lang="scss" scoped>
.video {
    width: 100%;
    height: 100%;
}
</style>