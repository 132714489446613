<template>
    <div ref="container">
        <canvas ref="canvas" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    props: ['camera'],

    data() {
        return {
            reloadCows: 5
        }
    },

    computed: {
        ...mapState({
            rerender: s => s.cameras.rerender,
            cows: s => s.cows.cows
        })
    },

    watch: {
        camera(newVal, oldVal) {
            this.stop(oldVal)
            this.stream(newVal)
        },

        rerender() {
            this.draw()
        }
    },

    mounted() {
        this.stream(this.camera)
        this.loadCows()
    },

    beforeDestroy() {
        this.stop(this.camera)
    },

    methods: {
        draw() {
            this.reloadCows--

            if(this.reloadCows <= 0) this.loadCows()

            let container = this.$refs.container
            let canvas = this.$refs.canvas
            let ctx = canvas.getContext("2d")
            let img = new Image()    

            img.onload = () => {
                window.requestAnimationFrame(() => {
                    canvas.width = container.clientWidth
                    canvas.height = (canvas.width / img.width) * img.height

                    ctx.fillStyle = "black"
                    ctx.fillRect(0,0, canvas.width, canvas.height)

                    let scale = Math.min(canvas.width / img.width, canvas.height / img.height);
                    let x = (canvas.width / 2) - (img.width / 2) * scale;
                    let y = (canvas.height / 2) - (img.height / 2) * scale;

                    ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

                    ctx.font = "16px courier"
                    ctx.textAlign = "left"
                    ctx.textBaseline = "bottom"

                    this.camera.playback.tracks.forEach(track => {
                        let x0 = x + track.bbox[0] * scale
                        let y0 = y + track.bbox[1] * scale
                        let x1 = x + track.bbox[2] * scale
                        let y1 = y + track.bbox[3] * scale

                        let text = track.cow.toString()

                        let color = 'red'

                        if(this.cows[track.cow]) {
                            let name = this.cows[track.cow].name

                            if(name && name.trim() != '') {
                                text += ' - ' + this.cows[track.cow].name
                            }

                            color = 'lime'
                        }

                        ctx.strokeStyle = color
                        ctx.strokeRect(x0, y0, x1-x0, y1-y0)

                        ctx.fillStyle = color
                        ctx.fillText(text, x0, y0)
                    })
                })
            }

            img.src = this.camera.playback.frame
        },

        ...mapActions({
            stream: 'cameras/stream',
            stop: 'cameras/stopStream',
            loadCows: 'cows/load'
        })
    }
}
</script>