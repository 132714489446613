<template>
  <v-navigation-drawer
    :value="value"
    @input="$emit('input', $event)"
    app
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">Kuhtracking</v-list-item-title>
        <v-list-item-subtitle v-if="$store.state.barn">{{$store.state.barn.name}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list nav dense>
      <v-list-item-group :value="current">
        <v-list-item v-for="item in menu" :key="item.path" @click="$router.push(item.path)">
          <v-list-item-icon>
            <v-icon v-if="item.meta.menuIcon">{{item.meta.menuIcon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{item.meta.menu}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <img class="ma-logo" src="ma_logo.svg" />
  </v-navigation-drawer>
</template>

<script>
export default {
  props: ["value"],

  computed: {
    menu() {
      let loggedIn = this.$store.getters["user/isValid"];

      return this.$router.options.routes
        .filter((r) => r.meta?.menu && (r.meta?.noLogin || loggedIn) && (!r.meta?.rights?.reduce((fail, r) => fail | this.$store.state.user.rights.includes(r), false) | this.$store.state.user.rights.includes('*')))
        .sort((a, b) => (a.meta.menuPos ?? 999) - (b.meta.menuPos ?? 999));
    },
    current() {
      return this.menu.findIndex(r => r.name == this.$router.currentRoute.name)
    }
  },
};
</script>

<style scoped>
.ma-logo {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1em;
}
</style>