import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import About from '../views/About'
import Barns from '../views/Barns'
import Positions from '../views/Positions'
import Forbidden from '../views/Forbidden'
import Users from '../views/Users'
import BarnsAdmin from '../views/BarnsAdmin'
import Cameras from '../views/Cameras'
import Cows from '../views/Cows'
import Heatmap from '../views/Heatmap'
import Activity from '../views/Activity'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/positions'
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: Forbidden,
    meta: {
      title: 'Forbidden',
      noLogin: true,
      noBarn: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login',
      noLogin: true,
      noBarn: true
    }
  },
  {
    path: '/barns',
    name: 'Barns',
    component: Barns,
    meta: {
      title: 'Select Barn',
      noBarn: true
    }
  },
  {
    path: '/positions',
    name: 'Positions',
    component: Positions,
    meta: {
      title: 'Positions',
      menu: 'Positions',
      menuPos: 1,
      menuIcon: 'mdi-map-marker'
    }
  },
  {
    path: '/activity',
    name: 'Activity',
    component: Activity,
    meta: {
      title: 'Activity',
      menu: 'Activity',
      menuPos: 2,
      menuIcon: 'mdi-chart-line'
    }
  },
  {
    path: '/heatmap',
    name: 'Heatmap',
    component: Heatmap,
    meta: {
      title: 'Heatmap',
      menu: 'Heatmap',
      menuPos: 3,
      menuIcon: 'mdi-thermometer'
    }
  },
  {
    path: '/cameras',
    name: 'Cameras',
    component: Cameras,
    meta: {
      title: 'Cameras',
      menu: 'Cameras',
      menuPos: 30,
      menuIcon: 'mdi-video'
    }
  },
  {
    path: '/cows',
    name: 'Cows',
    component: Cows,
    meta: {
      title: 'Cows',
      menu: 'Cows',
      menuPos: 31,
      menuIcon: 'mdi-cow'
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      title: 'Users',
      menu: 'Users',
      menuPos: 90,
      menuIcon: 'mdi-account-multiple',
      rights: ['list_user'],
      noBarn: true
    }
  },
  {
    path: '/barns_admin',
    name: 'Barns-Admin',
    component: BarnsAdmin,
    meta: {
      title: 'Barns',
      menu: 'Barns',
      menuPos: 91,
      menuIcon: 'mdi-barn',
      rights: ['all_barns'],
      noBarn: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'About',
      menu: 'About',
      menuPos: 100,
      menuIcon: 'mdi-information',
      noLogin: true,
      noBarn: true
    }
  },
]

const router = new VueRouter({
  routes
})

router.beforeResolve((to, from, next) => {
  store.commit('setTitle', to.meta?.title)

  if (!to.meta?.noLogin && !store.getters['user/isValid']) {
    if(to.path != '/login') {
      next('/login')
    } else {
      next()
    }
  } else if (!to.meta?.noBarn && !store.state.barn) {
    if(to.path != '/barns') {
      next('/barns')
    } else {
      next()
    }
  } else {
    next()
  }
})

router.beforeResolve((to, from, next) => {
  if(to.meta?.rights && to.meta?.rights?.reduce((fail, r) => fail | !store.state.user.rights.includes(r), false) && !store.state.user.rights.includes('*')) {
    next('/forbidden')
  } else {
    next()
  }
})

router.beforeEach(async (to, from, next) => {
  await store.restored
  next()
})

export default router
