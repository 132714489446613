<template>
    <div ref="map" class="map" @click="clickMap">
        <img class="img" :src="map" />
        <div v-for="(marker, idx) in markers" :key="idx" class="marker" :class="{clickable: marker.clickable}" :style="`top:${marker.y*100}%;left:${marker.x*100}%;background-color:${marker.color || '#90a0b8'}`">
            <div class="label" @click="clickMarker(marker)">{{marker.label}}</div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        map: {
            type: String,
            default: '',
        },
        markers: {
            type: Array,
            default: () => []
        }
    },

    methods: {
        clickMap(ev) {
            let x = ev.clientX
            let y = ev.clientY

            let rect = this.$refs.map.getBoundingClientRect()

            x -= rect.left
            y -= rect.top

            x /= rect.right - rect.left
            y /= rect.bottom - rect.top

            this.$emit('mapClick', {x, y})
        },

        clickMarker(marker) {
            this.$emit('markerClick', marker)
        }
    },
}
</script>

<style lang="scss" scoped>
.map {
    margin: 0;
    padding: 0;
    position: relative;
    display: inline-block;
    background: white;
}

.img {
    max-width: calc(100%);
    object-fit: contain;
    position: relative;
}

.marker {
    position: absolute;
    transform: translate(-50%, -50%);
    border: 1px black solid;
    border-radius: 100%;
    width: 1.5em;
    height: 1.5em;
}

.clickable {
    cursor: pointer;
}

.label {
    font-size: 0.7em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>