import fetcher from '../fetcher'
import {api_url} from '../config.json'

export default {
    namespaced: true,
    state: {
        error: false,
        cameras: {},
        rerender: 0,
    },

    mutations: {
        setError: (state, error) => state.error = error,
        setCameras: (state, cameras) => state.cameras = cameras,
        updateCamera: (state, update) => {
            let obj = {}
            obj[update.id] = update
            state.cameras = {...state.cameras, ...obj}
        },
        doRerender: (state) => state.rerender = Math.random()
    },

    actions: {
        async load({ commit, rootState }) {
            var res = await fetcher(api_url + '/data/barn/' + rootState.barn?.id?.toString() + '/cameras')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            let cameraArr = await res.json()

            let cameras = {}

            cameraArr.forEach(c => cameras[c.id] = c)

            commit('setCameras', cameras)
            commit('setError', false)
        },

        async edit({ commit, dispatch, rootState }, camera) {
            let cam = Object.assign({}, camera)
            cam.playback = undefined

            let res = await fetcher(api_url + '/data/barn/' + rootState.barn?.id?.toString() + '/camera/' + camera.id.toString(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cam)
            })

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            dispatch('load')
        },

        async loadLatest({ commit, rootState }, camera) {
            if(!camera.playback) {
                camera.playback = {
                    stamp: new Date(0),
                    frame: null,
                    tracks: []
                }
            }

            var base_url = api_url + '/data/barn/' + rootState.barn?.id?.toString() + '/camera/' + camera.id.toString()

            var res = await fetcher(base_url + '/latest_frame')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            let latest = await res.json()
            camera.playback.stamp = new Date(latest.stamp)
            
            base_url += '/frame/' + latest.stamp.toString()

            res = await fetcher(base_url + '/data')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            let blob = await res.blob()

            if(camera.playback.frame) {
                URL.revokeObjectURL(camera.playback.frame)
            }

            camera.playback.frame = URL.createObjectURL(blob)

            res = await fetcher(base_url + '/tracks')

            if (res.status != 200) {
                commit('setError', await res.text())
                return
            }

            camera.playback.tracks = await res.json()
            commit('updateCamera', camera)
            commit('setError', false)
            commit('doRerender')
        },

        async stream({ commit, dispatch }, camera) {
            if(!camera) return

            dispatch('loadLatest', camera)

            dispatch('stopStream', camera)
            camera.playback._interval = setInterval(() => dispatch('stream', camera), 1000)

            commit('updateCamera', camera)
            commit('setError', false)
        },

        stopStream({ commit }, camera) {
            if(!camera) return

            if (camera.playback._interval) {
                clearInterval(camera.playback._interval)
                camera.playback._interval = null
            }

            commit('updateCamera', camera)
        },

        stopAllStreams({ state, dispatch }) {
            for (let camera of state.cameras) {
                dispatch('stopStream', camera)
            }
        }
    }
}