<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title>
                edit {{barn.name}}
                <v-spacer />
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="barn.name" label="Name"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="save()">
                    save
                </v-btn>
                <v-spacer />
                <v-btn text @click="dialog = false">
                    cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            dialog: false,
            barn: {}
        }
    },

    methods: {
        show(barn) {
            this.dialog = true
            this.barn = JSON.parse(JSON.stringify(barn))
        },

        save() {
            this.editBarn(this.barn)

            this.dialog = false
        },

        ...mapActions({
            editBarn: 'barns/edit'
        })
    }
}
</script>