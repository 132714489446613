<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title>
                Confirmation
            </v-card-title>
            <v-card-text>
                <slot>
                </slot>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="setResult(true)">
                    yes
                </v-btn>
                <v-spacer />
                <v-btn text @click="setResult(false)">
                    no
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            promiseResolve: null
        }
    },

    watch: {
        dialog(val) {
            if(!val && this.promiseResolve) {
                this.setResult(false)
            }
        }
    },

    methods: {
        show() {
            this.dialog = true
            return new Promise((resolve) => this.promiseResolve = resolve)
        },

        setResult(value) {
            this.promiseResolve(value)
            this.promiseResolve = null
            this.dialog = false
        }
    }
}
</script>