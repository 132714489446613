<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="nav = !nav">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-toolbar-title>{{title}}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon @click.stop="user = !user">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-app-bar>

    <nav-menu v-model="nav"/>
    <user-menu v-model="user"/>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import NavMenu from './components/NavMenu.vue';
import UserMenu from './components/UserMenu.vue';

export default {
  name: "App",
  components: { NavMenu, UserMenu },

  data() {
    return {
      nav: window.innerWidth > 500 && window.innerheight > 500,
      user: false
    }
  },

  computed: {
    ...mapState(["title"]),
  },
};
</script>
