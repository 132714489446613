<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title>
                edit {{user.name}}
                <v-spacer />
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="user.name" label="Username"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="save()">
                    save
                </v-btn>
                <v-spacer />
                <v-btn text @click="dialog = false">
                    cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            dialog: false,
            user: {}
        }
    },

    methods: {
        show(user) {
            this.dialog = true
            this.user = JSON.parse(JSON.stringify(user))
        },

        save() {
            this.editUser(this.user)

            this.dialog = false
        },

        ...mapActions({
            editUser: 'users/edit'
        })
    }
}
</script>