<template>
    <line-chart :data="data" />
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    props: ['cows'],

    data() {
        return {
            isLoading: true
        }
    },

    computed: {
        data() {
            if(this.isLoading) {
                return []
            }

            return this.cows.map(cow => ({
                name: cow.id.toString() + (cow.name && cow.name.trim() != '' ? ' - ' + cow.name : ''),
                data: this.activities[cow.id] ? this.activities[cow.id].reduce((d, a) => {
                    d.push([new Date(a.stamp), a.activity])
                    return d
                }, []) : []
            }))
        },

        ...mapState({
            activities: s => s.cows.activities
        })
    },

    watch: {
        cows() {
            this.load()
        }
    },

    methods: {
        load() {
            this.isLoading = true
            let promises = this.cows.map(c => this.loadActivity(c))
            Promise.all(promises).then(() => this.isLoading = false).catch(() => this.isLoading = false)
        },

        ...mapActions({
            loadActivity: 'cows/loadActivity'
        })
    },

    mounted() {
        this.load()
    }
}
</script>