<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title>
                New User
                <v-spacer />
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="newUsername" label="Username" required></v-text-field>
                <v-text-field v-model="newPassword" label="Password" :type="newShowPw ? 'text' : 'password'" :append-icon="newShowPw ? 'mdi-eye' : 'mdi-eye-off'" @click:append="newShowPw = !newShowPw" required></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="newUser()">
                    create
                </v-btn>
                <v-spacer />
                <v-btn text @click="dialog = false">
                    cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {

    data() {
        return {
            dialog: false,
            newUsername: '',
            newPassword: '',
            newShowPw: false
        }
    },

    methods: {
        show() {
            this.newUsername = ''
            this.newPassword = ''
            this.dialog = true
        },

        newUser() {
            var credentials = {
                name: this.newUsername,
                password: this.newPassword
            }

            this.addUser(credentials)

            this.dialog = false
        },

        ...mapActions({
            addUser: 'users/add'
        })
    }
}
</script>